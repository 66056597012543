<template>
 <v-row no-gutters justify="center" align="center" class="mt-4">
  <v-card width="1000px" height="800">
    <v-container>
      <h2 class="my-5"> ระบบติดตามผู้เสพยาเสพติด </h2>
      <v-row>
        <v-col cols="4" class="mr-4">
          <v-text-field
            v-model="search"
            dense
            hide-details
            outlined
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :search="search"
        :items-per-page="10"
        class="elevation-1"
      >
        <!-- <template v-slot:[`item.idCardImg`]="{ item }">
          <img @click="popup(item)" :src="item.idCardImg" />
        </template> -->

        <!-- <template v-slot:[`item.activeFlag`]="{ item }">
          <v-row justify="center">
            <v-btn v-if="item.activeFlag === true" disabled>อนุมัติแล้ว</v-btn>
            <v-btn @click="approve(item)" v-else>รออนุมัติ</v-btn>
          </v-row>
        </template> -->
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <!-- <v-icon @click="deleteData(item)">mdi-delete</v-icon> -->
            <v-icon @click="Editdata(item)">mdi-magnify</v-icon>
          </v-row>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
 </v-row>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      //   dialog: false,
      headers: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อ-นามสกุล", value: "fullName", align: "center" },
        { text: "พื้นที่", value: "area", align: "center" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "วันที่ตรวจ", value: "date", align: "center" },
        { text: "การจัดการ", value: "action", align: "center" },
        // { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      list: [
        {
          fullName: "ทรงชัย ไชยเลิศ",
          area: "สภ.แม่เหียะ",
          status: "บำบัด",
          date: "21/03/2021",
        },
        {
          fullName: "วัลลภ ยอดผัก",
          area: "สภ.แม่เหียะ",
          status: "บำบัด",
          date: "20/03/2021",
        },
        {
          fullName: "ณัฐพงศ์ เกียรติลังกา",
          area: "สภ.หางดง",
          status: "ปกติ",
          date: "18/03/2021",
        },
        {
          fullName: "มงคล สัจจะ",
          area: "สภ.หางดง",
          status: "บำบัด",
          date: "9/03/2021",
        },
        {
          fullName: "เกียรติ ปัญญาเลิศ",
          area: "สภ.หางดง",
          status: "ติดยา",
          date: "1/03/2021",
        },
      ],
      count: 0,
    };
  },
  created() {
      this.getUser()
  },
  methods: {
    getUser() {
      for (let i in this.list) {
        this.list[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
  },
};
</script>
