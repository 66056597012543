<template>
  <v-row no-gutters justify="center" align="center">
    <v-card width="1000px">
      <v-container>
        <v-row no-gutters justify="center" align="center" class="mt-10">
          <v-img
            max-height="100px"
            max-width="100px"
            src="../assets/logo_survey.png"
          ></v-img>
        </v-row>
        <v-row justify="center" align="center" class="text-center">
          <v-col cols="12" md="8" sm="12">
            เลือกสถานีหรือชุมชนที่ท่านอยู่อาศัย
            <v-autocomplete
              class="mt-6"
              dense
              outlined
              placeholder="ค้นหา"
              v-model="dropdown"
              :items="items"
              item-text="searchText"
              item-value="station"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="text-center">
          <v-col cols="6"><v-btn @click="goToSearch()">ค้นหา</v-btn></v-col>
        </v-row>
        <v-flex v-for="(n, index) in qrList" :key="index">
          <v-card class="pa-6 mt-2" v-if="n.formId == 23">
            <!-- <v-col cols="12" class="headtitle" justify="center" align="center">
              <vue-qrcode :value="n.url" :width="300" />
            </v-col> -->
           
            <v-col cols="12" class="headtitle" justify="center" align="center">
              {{ n.name }} <br />
              {{ n.village }}<br /><br />
              <p style="color: black">สำหรับ {{ n.description }}</p>
            </v-col>
             <v-col cols="12" class="headtitle" justify="center" align="center">
              <!-- <a v-bind:href="n.url">{{ n.url }}</a> -->
              <v-btn v-bind:href="n.url" color="#833133" style="color: white">คลิกที่นี่เพื่อทำแบบประเมิน</v-btn>
            </v-col>
          </v-card>
        </v-flex>
      </v-container>
    </v-card>
  </v-row>
</template>

<script>
import VueQrcode from "vue-qrcode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    VueQrcode,
    Loading,
  },
  data() {
    return {
      search: "",
      qrList: [],
      dropdown: "",
      items: [],
      printvisible: true,
    };
  },
  methods: {
    doMath: function (index) {
      return index + 1;
    },
    async searchDropdown() {
      // const response = await this.axios.get(
      //   `https://policesurveyservice.yuzudigital.com/form/getAllStation`
      // );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        item.searchText = item.station + " " + item.village;
      });
    },
    async goToSearch() {
      console.log("searchhhh", this.dropdown);
      const response = await this.axios.get(
      //   `https://policesurveyservice.yuzudigital.com/form/getAllQrCode?station=` +
      //     this.dropdown
      // );
        `${process.env.VUE_APP_API}/form/getAllQrCode?station=` +
          this.dropdown
      );
      console.log("response", response);
      this.qrList = response.data.data;
      this.qrList.forEach((qr) => {
        qr.url = `${process.env.VUE_APP_FRONTEND}/survey?qrCodeId=` + qr.id;
      });
    },
    printpo() {
      setTimeout(() => {
        window.print();
      }, 500);
      this.printvisible = false;
      setTimeout(() => {
        this.printvisible = true;
      }, 2000);
    },
  },
  async created() {
    this.searchDropdown();
    const url = this.$router.currentRoute.query;
    // if (url.station) {
    //   const response = await this.axios.get(
    //     // `${process.env.VUE_APP_API}/form/getAllQrCode`
    //     `${process.env.VUE_APP_API}/form/getAllQrCode?station=` +
    //       url.station
    //   );
    //   console.log("response", response);
    //   this.qrList = response.data.data;
    //   this.qrList.forEach((qr) => {
    //     qr.url = "https://ชุมชนยั่งยืน.com/#/survey?qrCodeId=" + qr.id;
    //   });
    // } else {
    //   const response = await this.axios.get(
    //     // `${process.env.VUE_APP_API}/form/getAllQrCode`
    //     `${process.env.VUE_APP_API}/form/getAllQrCode`
    //   );
    //   console.log("response", response);
    //   this.qrList = response.data.data;
    //   this.qrList.forEach((qr) => {
    //     qr.url = "https://ชุมชนยั่งยืน.com/#/survey?qrCodeId=" + qr.id;
    //   });
    // }
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
.headtitle1 {
  font-weight: 800;
  color: #833133;
}
</style>
