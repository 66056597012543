<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <!-- <v-row align="center" justify="center" class="text-center" no-gutters> -->
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <v-card v-if="!selected" width="1000px">
        <v-container>
          <v-row no-gutters justify="center" align="center" class="mt-10">
            <v-img
              max-height="100px"
              max-width="100px"
              src="@/assets/logo_survey.png"
            ></v-img>
          </v-row>
          <v-row justify="center" align="center" class="text-center">
            <v-col cols="12" md="10" sm="12" xs="10">
              เลือกชุมชนที่ท่านพักอาศัย
              <v-autocomplete
                class="mt-6"
                dense
                outlined
                label="ค้นหา"
                v-model="dropdown"
                :items="items"
                return-object
                item-text="searchText"
                item-value="items"
                :rules="rules.required"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="text-center">
            <v-col cols="6"
              ><v-btn @click="goToNext(dropdown)">ถัดไป</v-btn></v-col
            >
          </v-row>
        </v-container>
      </v-card>
      <v-card v-else width="1000px" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form>
          <v-row align="center" justify="center" class="text-center" no-gutters>
            <p style="font-size: 18px" class="headtitle text-center">
              ระบบแจ้งข่าวสารการกระทำผิด {{ station + " " + village }}
            </p>
          </v-row>
          <v-row v-for="(n, index) in question1" :key="index">
            <v-col cols="12" md="12" sm="12"
              ><p class="text-left" style="font-size: 18px">
                <b>ข้อมูลผู้มีพฤติกรรมกระทำความผิด </b>
              </p></v-col
            >
            <!-- <v-col cols="12">
                <p class="text-left" style="font-size: 18px">
                  <b>{{ question1[index].question }}</b>
                </p>
              </v-col> -->
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="question1[index].typeWrong"
                label="ลักษณะการทำความผิด*"
                :items="[
                  'เสพ',
                  'ค้า',
                  'สมคบ,สนับสนุน',
                  'ช่วยเหลือ',
                  'ความผิดเกี่ยวกับทรัพย์',
                  'ความผิดเกี่ยวกับชีวิตร่างกายและเพศ',
                  'ความผิดเกี่ยวกับการขับขี่รถด้วยความเร็ว',
                  'ความผิดเกี่ยวกับเสียงดังก่อกวน',
                ]"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12"></v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="question1[index].name"
                label="ชื่อ-สกุล*"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="question1[index].nickName"
                label="ชื่อเล่น/ฉายา"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="question1[index].gender"
                label="เพศ*"
                :items="['ชาย', 'หญิง']"
                :rules="rules.required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="question1[index].age"
                label="อายุประมาณ*"
                type="number"
                :rules="rules.age"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="question1[index].helper"
                label="ข้อมููลผู้ร่วม/ช่วยเหลือ/สนับสนุน"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                v-model="question1[index].description"
                rows="3"
                filled
                label="รายละเอียด"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="question1[index].wrongLocation"
                label="สถานที่กระทำผิด*"
                hint="โปรดระบุ ชื่อสถานที่/บ้านเลขที่/ตึกอาคาร/ห้อง/ชั้น/ตรอก/ซอย/ถนน"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="question1[index].vehicle"
                label="ยานพาหนะ"
                hint="โปรดระบุ ประเภทรถ/ยี่ห้อรถ/สีรถ/หมายเลขทะเบียน"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group v-model="question1[index].wrongAddressChoice">
                ที่อยู่ปัจจุบันของผู้กระทำความผิด*
                <v-radio
                  label="ที่อยู่เดียวกับสถานที่กระทำผิด"
                  value="same"
                ></v-radio>
                <v-radio label="ใส่รายละเอียดที่อยู่ใหม่" value="new"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
              xs="12"
              v-if="question1[index].wrongAddressChoice == 'new'"
            >
              <v-text-field
                v-model="question1[index].wrongAddress"
                label="ระบุที่อยู่ปัจจุบันของผู้กระทำความผิด*"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                v-model="question1[index].behavior"
                rows="3"
                filled
                label="รายละเอียดพฤติการณ์ผู้กระทำความผิด"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group
                v-model="question1[index].thisArea"
                :rules="rules.required"
              >
                เป็นบุคคลในพื้นที่หรือไม่*
                <v-radio label="ใช่" value="Yes"></v-radio>
                <v-radio label="ไม่ใช่" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="12"
              xs="12"
              v-if="
                question1[index].typeWrong == 'เสพ' ||
                question1[index].typeWrong == 'ค้า'
              "
            >
              ข้อมูลยาเสพติด*
              <v-row>
                <v-checkbox
                  v-model="question1[index].drugBah"
                  label="ยาบ้า"
                ></v-checkbox>
                <v-checkbox
                  v-model="question1[index].drugHeloine"
                  label="เฮโรอีน"
                ></v-checkbox>
                <v-checkbox
                  v-model="question1[index].drugIce"
                  label="ไอซ์"
                ></v-checkbox>
                <v-checkbox
                  v-model="question1[index].drugE"
                  label="ยาอี"
                ></v-checkbox>
                <v-checkbox
                  v-model="question1[index].drugKancha"
                  label="กัญชา"
                ></v-checkbox>
                <v-checkbox
                  v-model="question1[index].drugKatom"
                  label="กระท่อม"
                ></v-checkbox>
                <v-checkbox
                  v-model="question1[index].drugK"
                  label="ยาเค"
                ></v-checkbox>
                <v-checkbox
                  v-model="question1[index].drug"
                  label="อื่นๆ"
                ></v-checkbox>
                <v-checkbox
                  v-model="question1[index].notHave"
                  label="ไม่มี"
                ></v-checkbox>
                <v-text-field
                  v-if="question1[index].drug == true"
                  label="ยาอื่นๆ โปรดระบุ"
                  v-model="question1[index].drugOther"
                  :rules="rules.required"
                ></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-img :src="showImage1"></v-img>
              <v-text-field
                v-model="question1[index].picture_1"
                @click="changePic1()"
                :label="question1[index].imageName1"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure1"
                accept="image/*"
                @change="showPicture1($event, index)"
                style="display: none"
            /></v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-img :src="showImage2"></v-img>
              <v-text-field
                v-model="question1[index].picture_2"
                @click="changePic2()"
                :label="question1[index].imageName2"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure2"
                accept="image/*"
                @change="showPicture2($event, index)"
                style="display: none"
            /></v-col>
          </v-row>
          <!-- <v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-btn @click="deleteRow1()" rounded block dense
                  ><v-icon>mdi-minus-circle</v-icon>ลบผู้เกี่ยวข้อง</v-btn
                ></v-col
              >
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-btn
                  @click="addRow1()"
                  rounded
                  block
                  dense
                  color="#833133"
                  style="color: white"
                  ><v-icon>mdi-plus-circle</v-icon>เพิ่มผู้เกี่ยวข้อง</v-btn
                >
              </v-col>
            </v-row> -->
          <br />
          <v-btn class="mt-4" color="primary" @click="submit()">แจ้งข่าว</v-btn>
        </v-form>
      </v-card>
    </v-form>
    <!-- </v-row> -->
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      questions: [],
      question1: [],
      question2: [],
      question3: [],
      question4: [],
      question5: [],
      question6: [],
      question7: [],
      question8: [],
      village: "",
      station: "",
      district: "",
      subDistrict: "",
      province: "",
      group: "",
      showImage1: "",
      showImage2: "",
      dropdown: "",
      loading: false,
      // detail: "",
      // wrongAddressChoice: "",
      area: [],
      items: [],
      selected: false,
      lazy: false,
      checkValidate: true,
      rules: {
        required: [(v) => !!v || "กรุณาใส่ข้อมูล"],
        password: [(v) => !!v || "Password is required"],
        image: [(v) => !!v || "Image is required"],
        name: [(v) => !!v || "Name is required"],
        sername: [(v) => !!v || "Sername is required"],
        duty: [(v) => !!v || "Duty is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        age: [
          (v) => v.length == 2 || "อายุต้องอยู่ระหว่าง 1-99 ปี เท่านั้น",
          (v) => v >= 1 || "อายุต้องอยู่ระหว่าง 1-99 ปี เท่านั้น",
        ],
        phone: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "หมายเลขโทรศัพท์ต้องมี 9-10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],
        selectRules: [(val) => !!val || "กรุณากรอกข้อมูล"],
      },
    };
  },
  created() {
    this.searchDropdown();
  },
  methods: {
    goToNext(dropdown) {
      if (this.$refs.createForm.validate(true)) {
        console.log("print", dropdown);
        this.selected = true;
        this.station = dropdown.station;
        this.village = dropdown.village;
        this.district = dropdown.district;
        this.subDistrict = dropdown.subDistrict;
        this.province = dropdown.province;
        this.group = dropdown.group;

        this.addRow1();
        this.addRow2();
        this.addRow3();
        this.addRow4();
        this.addRow5();
        this.addRow6();
        this.addRow7();
        this.addRow8();
      }
    },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        item.searchText = item.station + " " + item.village;
      });
    },

    async addRow1() {
      this.question1.push({
        question: "ผู้ที่ขายยาเสพติดในชุมชน",
        typeWrong: "",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        // ยาบ้า/เฮโรอีน/ไอซ์/ยาอี/กัญชา/กระท่อม/ยาเค/อื่นๆ ระบุ
        drugBah: false,
        drugHeloine: false,
        drugIce: false,
        drugE: false,
        drugKancha: false,
        drugKatom: false,
        drugK: false,
        notHave: false,
        drugOther: "",
        // name nickName gender age helper description wrongLocation vehicle wrongAddressChoice wrongAddress behavior thisArea drug
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        imageName1: "ภาพถ่าย(ถ้ามี)",
        imageName2: "ภาพถ่าย(ถ้ามี)",
        pic1: this.pic1,
        pic2: this.pic2,
        status: "ยังไม่ได้ดำเนินการ",
      });
    },
    addRow2() {
      this.question2.push({
        question: "ผู้ที่เสพยาเสพติด/มั่วสุม/เกี่ยวข้องยาเสพติด",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        status: "ยังไม่ได้ดำเนินการ",
      });
    },
    addRow3() {
      this.question3.push({
        question: "ผู้ที่มีอาวุธปืนเถือน/วัตถุระเบิด/อาวุธสงคราม",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        status: "ยังไม่ได้ดำเนินการ",
      });
    },
    addRow4() {
      this.question4.push({
        question: "ผู้ที่มีพฤติกรรมลักเล็กขโมยน้อย",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        status: "ยังไม่ได้ดำเนินการ",
      });
    },
    addRow5() {
      this.question5.push({
        question:
          "ผู้ที่มีพฤติกรรมไม่เรียบร้อย /จับกลุ่มดื่มสุรามั่วสุม/ชอบนำพวกทะเลาะวิวาท",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        status: "ยังไม่ได้ดำเนินการ",
      });
    },
    addRow6() {
      this.question6.push({
        question: "ผู้ที่ขับรถแว้น คึกคะนอง เสียงดัง",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        status: "ยังไม่ได้ดำเนินการ",
      });
    },
    addRow7() {
      this.question7.push({
        question: "ผู้ที่มีพฤติกรรมชอบเล่นการพนัน/ขายหวยออนไลน์ใต้ดิน",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        status: "ยังไม่ได้ดำเนินการ",
      });
    },
    addRow8() {
      this.question8.push({
        question:
          "ผู้ที่มีพฤติกรรมไม่เรียบร้อยอื่นๆ ทั้งในชุมชน หรือ ชุมชนใกล้เคียง",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        status: "ยังไม่ได้ดำเนินการ",
      });
    },
    deleteRow1() {
      if (this.question1.length > 1) {
        this.question1.pop();
      }
    },
    deleteRow2() {
      if (this.question2.length > 1) {
        this.question2.pop();
      }
    },
    deleteRow3() {
      if (this.question3.length > 1) {
        this.question3.pop();
      }
    },
    deleteRow4() {
      if (this.question4.length > 1) {
        this.question4.pop();
      }
    },
    deleteRow5() {
      if (this.question5.length > 1) {
        this.question5.pop();
      }
    },
    deleteRow6() {
      if (this.question6.length > 1) {
        this.question6.pop();
      }
    },
    deleteRow7() {
      if (this.question7.length > 1) {
        this.question7.pop();
      }
    },
    deleteRow8() {
      if (this.question8.length > 1) {
        this.question8.pop();
      }
    },
    showPicture1(e, index) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.question1[index].imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.question1[index].pic1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
          console.log(this.question1[index].pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e, index) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.question1[index].imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.question1[index].pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
          console.log(this.question1[index].pic2);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        let checklogin = JSON.parse(
          Decode.decode(localStorage.getItem("user"))
        );
        for (const i of this.question1) {
          if (i.wrongAddressChoice == "same") {
            i.wrongAddress = i.wrongLocation;
          }
          if (
            // (
            i.typeWrong != "" &&
            i.name != "" &&
            i.gender != "" &&
            i.age != "" &&
            i.wrongLocation != "" &&
            i.same != "" &&
            i.thisArea != ""
            // &&
            // i.drugBah
            //   ) ||
            // i.drugHeloine ||
            // i.drugIce ||
            // i.drugE ||
            // i.drugKancha ||
            // i.drugKatom ||
            // i.drugK ||
            // i.notHave ||
            // i.drug ||
            // i.drugOther != false
          ) {
            i.userId = checklogin.id;
            this.questions.push(i);
            console.log("question1", this.question1);
          }
        }
        console.log("questions", this.questions);
        if (this.questions.length !== 0) {
          this.loading = true;
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/drugclues/create`,
            this.questions
          );
          console.log("response", response);
          if (response.data.response_status === "SUCCESS") {
            this.loading = false;
            await this.$swal.fire({
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              icon: "success",
              text: "บันทึกข้อมูลสำเร็จ",
            });
            location.reload();
          } else {
            this.loading = false;
            await this.$swal.fire({
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              icon: "error",
              text: response.data.message,
            });
          }
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบ",
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>