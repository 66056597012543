<template>
  <v-container style="background-color: #172b4d">
    <v-row no-gutters justify="center" align="center">
      <v-card v-if="!selected" width="1000px">
        <v-container>
          <v-row no-gutters justify="center" align="center" class="mt-10">
            <v-img
              max-height="100px"
              max-width="100px"
              src="@/assets/logo_survey.png"
            ></v-img>
          </v-row>
          <v-row justify="center" align="center" class="text-center">
            <v-col cols="8">
              เลือกชุมชนที่ท่านพักอาศัย
              <v-autocomplete
                class="mt-6"
                dense
                outlined
                placeholder="ค้นหา"
                v-model="dropdown"
                :items="items"
                return-object
                item-text="searchText"
                item-value="items"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="text-center">
            <v-col cols="6"
              ><v-btn @click="goToNext(dropdown)">ถัดไป</v-btn></v-col
            >
          </v-row>
        </v-container>
      </v-card>
      <v-card v-else class="pa-6" width="600px">
        <v-row no-gutters justify="center" align="center">
          <v-img
            max-height="180px"
            max-width="180px"
            src="../assets/logo_survey.png"
          ></v-img>
          <v-col cols="12" class="pt-10 headtitle"
            ><h3>แบบสำรวจสภาพชุมชน</h3></v-col
          >
        </v-row>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <h3 class="headtitle1">{{ station + " " + village }}</h3>

        <v-col cols="12" class="headtitle" v-model="gender">เพศ</v-col>
        <v-radio-group>
          <v-radio label="ชาย" value="male"></v-radio>
          <v-radio label="หญิง" value="female"></v-radio>
        </v-radio-group>

        <v-col cols="12" class="headtitle">อายุ</v-col>
        <v-text-field type="number">ปี</v-text-field>

        <v-col cols="12" class="headtitle">ระดับการศึกษา</v-col>
        <v-select v-model="graduate" :items="graduateitems"></v-select>

        <v-col cols="12" class="headtitle">ที่พักอาศัยปัจุจบัน</v-col>
        <v-select v-model="homestay" :items="homestayitems"></v-select>

        <v-col cols="12" class="headtitle"
          >อาศัยอยู่ในชุมชน/หมู่บ้านนี้มาเป็นเวลา</v-col
        >
        <v-select v-model="hometime" :items="hometimeitems"></v-select>

        <v-col cols="12" class="headtitle">อาชีพ</v-col>
        <v-select v-model="job" :items="jobitems"></v-select>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"
          >ท่านอาศัยอยู่ในชุมชน/หมูบ้านนี้มีความสุขมากน้อยเพียงใด</v-col
        >
        <!-- <v-select v-model="happy" :items="happyitems"></v-select> -->
         <v-radio-group>
          <v-radio label="มีความสุขมาก มีความปลอดภัย" value="มีความสุขมาก มีความปลอดภัย"></v-radio>
          <v-radio label="มีความสุขตามอัตภาพ" value="มีความสุขตามอัตภาพ"></v-radio>
          <v-radio label="ไม่มีค่อยมีความสุข" value="ไม่มีค่อยมีความสุข"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"
          >ในชุมชน/หมู่บ้านที่ท่านอาศัยอยู่มีปัญหาเหล่านี้หรือไม่</v-col
        >
        <!-- <v-select v-model="question1" :items="choiceitems"></v-select> -->
         <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีการขายยาเสพติดในชุมชน</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
       <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีการเสพยาเสพติด/มั่วสุม/เกี่ยวข้องยาเสพติด</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีการครองครอง อาวุธปืนเถื่อน/วัตถุระเบิด/อาวุธสงคราม</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
       <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีพฤติกรรมลักเล็กขโมยน้อย</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
       <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีพฤติกรรมจับกลุ่มดื่มสุรามั่วสุม/ชอบนำพวกทะเลาะวิวาท</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
       <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีการขับรถแว้น คึกคะนอง เสียงดัง</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีพฤติกรรมชอบเล่นการพนัน/ขายหวยออนไลน์ใต้ดิน</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
       <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีพฤติกรรมลักลอบตัดไม้หวงข้าม/ไม่มีค่า</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีพฤติกรรมบุกรุกที่สาธารณะ/ทำลายทรัพยากรธรรมชาติ</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีพฤติกรรมลักลอบจำหน่ายสินค้าหนีภาษี</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีพฤติกรรมออกเงินกู้นอกระบบ</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> มีพฤติกรรมเป็นผู้มีอิทธิพล</v-col>
        <v-radio-group>
          <v-radio label="มี" value="มี"></v-radio>
          <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
          <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle"> ท่านคิดว่าสถานการณ์การแพร่ระบาดยาเสพติดในชุมชนท่านอยู่ระดับใด</v-col>
        <v-radio-group>
          <v-radio label="รุนแรง(มีทั้งผู้เสพ/ผู้ขายหลายรายอยู่ในชุมชน)" value="รุนแรง(มีทั้งผู้เสพ/ผู้ขายหลายรายอยู่ในชุมชน)"></v-radio>
          <v-radio label="ปานกลาง(มีทั้งจำนวนผู้เสพ/ผู้ขาย อยู่ในชุมชน)" value="ปานกลาง(มีทั้งจำนวนผู้เสพ/ผู้ขาย อยู่ในชุมชน)"></v-radio>
          <v-radio label="เบาบาง(มีผู้เสพในชุมชน แต่ ผู้ขาย มาจากที่อื่น)" value="เบาบาง(มีผู้เสพในชุมชน แต่ ผู้ขาย มาจากที่อื่น)"></v-radio>
        </v-radio-group>
      </v-card>
      <v-card class="pa-6 mt-2" width="600px">
        <v-col cols="12" class="headtitle">ปัญหาอื่นๆ หรือข้อเสนอแนะ เพิ่มเติมในเรื่องผิดกฎหมายต่างๆ</v-col>
        <v-col cols="12">
          <v-text-field v-model="comment1"></v-text-field
        ></v-col>
        <v-btn color="#833133" style="color: white" @click="submit()"
          >ส่งแบบสอบถาม</v-btn
        >
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { Decode, Encode } from "@/services";
import StarRating from "vue-star-rating";
export default {
  components: {
    StarRating,
  },
  data() {
    return {
      village: "",
      station: "",
      items: [],
      selected: false,
      graduateitems: [
        { text: "ประถมศึกษา", value: "ประถมศึกษา" },
        { text: "มัธยมศึกษาตอนต้น", value: "มัธยมศึกษาตอนต้น" },
        { text: "มัธยมศึกษาตอนปลาย", value: "มัธยมศึกษาตอนปลาย" },
        { text: "ปวช/ปวส", value: "ปวช/ปวส" },
        { text: "ปริญาตรี", value: "ปริญาตรี" },
        { text: "สูงกว่าปริญญาตรี", value: "สูงกว่าปริญญาตรี" },
      ],
      homestayitems: [
        { text: "บ้านตนเอง", value: "บ้านตนเอง" },
        { text: "บ้านเช่า", value: "บ้านเช่า" },
        { text: "อาศัยอยู่กับญาติ ", value: "อาศัยอยู่กับญาติ " },
      ],
      hometimeitems: [
        { text: "ไม่ถึง 1 ปี", value: "ไม่ถึง 1 ปี" },
        { text: "1-5  ปี", value: "1-5  ปี" },
        { text: "6-10 ปี", value: "6-10 ปี" },
        { text: "10  ปี ขึ้นไป", value: "10  ปี ขึ้นไป" },
      ],
      jobitems: [
        { text: "ว่างงาน", value: "ว่างงาน" },
        { text: "นักเรียน/นักศึกษา", value: "นักเรียน/นักศึกษา" },
        {
          text: "ค้าขาย/ประกอบธุรกิจส่วนตัว",
          value: "ค้าขาย/ประกอบธุรกิจส่วนตัว",
        },
        {
          text: "ข้าราชการ/พนักงานรัฐวิสาหกิจ",
          value: "ข้าราชการ/พนักงานรัฐวิสาหกิจ",
        },
        { text: "ลูกจ้างบริษัท", value: "ลูกจ้างบริษัท" },
        { text: "เกษตรกร", value: "เกษตรกร" },
      ],
      happyitems: [
        {
          text: "มีความสุขมาก มีความปลอดภัย",
          value: "มีความสุขมาก มีความปลอดภัย",
        },
        { text: "มีความสุขตามอัตภาพ", value: "มีความสุขตามอัตภาพ" },
        {
          text: "ไม่มีค่อยมีความสุข รู้สึกไม่ปลอดภัย",
          value: "ไม่มีค่อยมีความสุข รู้สึกไม่ปลอดภัย",
        },
      ],
      choiceitems: [
        { text: "มี", value: "มี" },
        { text: "ไม่มี", value: "ไม่มี" },
        { text: "ไม่แน่ใจ", value: "ไม่แน่ใจ" },
      ],
    };
  },
  created() {
    this.searchDropdown();
  },
  methods: {
    async submit() {},
    goToNext(dropdown) {
      console.log("print", dropdown);
      this.selected = true;
      this.station = dropdown.station;
      this.village = dropdown.village;
      this.district = dropdown.district;
      this.subDistrict = dropdown.subDistrict;
      this.province = dropdown.province;
      this.group = dropdown.group;
      // this.question1.push({
      //   station: dropdown.station,
      //   village: dropdown.village,
      //   district: dropdown.district,
      //   subDistrict: this.subDistrict,
      //   province: this.province,
      // })
      console.log(
        "logggg",
        this.station,
        this.village,
        this.district,
        this.subDistrict,
        this.province,
        this.group
      );

    },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        item.searchText = item.station + " " + item.village;
      });
    },
  },
};
</script>
<style scoped>
.headtitle1 {
  font-weight: 800;
  color: #833133;
}
.headtitle {
  font-weight: 400;
  color: #833133;
}
</style>