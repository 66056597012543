<template>
  <v-row
    no-gutters
    justify="center"
    align="center"
    class="mt-4"
    style="margin-top: -20px"
  >
    <v-card>
      <v-row>
        <v-col v-for="(n, index) in qrList" :key="index" class="pt-2 pb-0 px-0">
          <v-card class="px-0 py-2 mt-2" width="180px">
            <v-col cols="12" class="headtitle pa-0 ma-0" justify="center" align="center">
              <v-row justify="center" align="center"
                ><vue-qrcode :value="n.url" max-width="20px" />
              </v-row>
            </v-col>
            <v-col cols="12" class="headtitle pa-0 ma-0" justify="center" align="center">
              <a style="font-size: 10px" v-bind:href="n.url">{{ n.url }}</a>
            </v-col>
            <v-col
              cols="12"
              class="headtitle pa-0 ma-0"
              justify="center"
              align="center"
              style="font-size: 10px"
            >
              {{ n.name }} <br />
              {{ n.village }}<br />{{ n.description }}
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import VueQrcode from "vue-qrcode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    VueQrcode,
    Loading,
  },
  data() {
    return {
      search: "",
      qrList: [],
    };
  },
  methods: {
    doMath: function (index) {
      return index + 1;
    },
  },
  async created() {
    const url = this.$router.currentRoute.query;
    if (url.station) {
      const response = await this.axios.get(
        // `${process.env.VUE_APP_API}/form/getAllQrCode`
        `${process.env.VUE_APP_API}/form/getAllQrCode?station=` +
          url.station
      );
      console.log("response", response);
      this.qrList = response.data.data;
      this.qrList.forEach((qr) => {
        qr.url = "https://police.ชุมชนยั่งยืน.com/survey?qrCodeId=" + qr.id;
      });
    } else {
      const response = await this.axios.get(
        // `${process.env.VUE_APP_API}/form/getAllQrCode`
        `${process.env.VUE_APP_API}/form/getAllQrCode`
      );
      console.log("response", response);
      this.qrList = response.data.data;
      this.qrList.forEach((qr) => {
        qr.url = "https://police.ชุมชนยั่งยืน.com/survey?qrCodeId=" + qr.id;
      });
    }
  },
};
</script>
<style scoped>
.headtitle {
  
  color: #833133;
  font-size: 10px;
}
.headtitle1 {
  font-weight: 800;
  color: #833133;
}
</style>
