import Vue from 'vue'
import VueRouter from 'vue-router'
import uuid from '../views/uuid.vue'
import survey from '../views/survey.vue'
import survey1 from '../views/survey1.vue'
import survey2 from '../views/survey2.vue'
import survey3 from '../views/survey3.vue'
import FormSheet2 from '../views/DrugForm/FormSheet2'
import FormSheet2Police from '../views/DrugForm/FormSheet2Police'
import FormSheet3 from '../views/DrugForm/FormSheet3'
import FormSheet4 from '../views/DrugForm/FormSheet4'
// import report from '../views/report.vue'
import table from '../views/table.vue'
import ExpireSurvey from '../views/ExpireSurvey.vue'
import Homepage from '@/views/Homepage.vue'
import QRCodeList from '../views/QRCodeList.vue'
import BeforeDateSurvey from '../views/BeforeDateSurvey'
import qrcode from '../views/qrcode.vue'
import SearchQR from '../views/DrugForm/Search.vue'
import login from '../views/Login.vue'
import ManageUser from '../views/ManageUser.vue'
import addUser from '../views/addUser.vue'
import editQRCode from '../views/editQRCode.vue'
import finalSurveyPeople from '../views/finalSurveyPeople.vue'
import finalSurveyOfficer from '../views/finalSurveyOfficer.vue'

Vue.use(VueRouter)

const routes = [{

        path: '/',
        name: 'login',
        component: login
    },
    {
        path: '/uuid',
        name: 'uuid',
        component: uuid
    },
    {
        path: '/survey',
        name: 'survey',
        component: survey
    },
    {
        path: '/survey1',
        name: 'survey1',
        component: survey1
    },
    {
        path: '/survey2',
        name: 'survey2',
        component: survey2
    },
    {
        path: '/survey3',
        name: 'survey3',
        component: survey3
    },
    {
        path: '/Form2',
        name: 'FormSheet2',
        component: FormSheet2
    },
    {
        path: '/table',
        name: 'table',
        component: table
    },
    {
        path: '/ExpireSurvey',
        name: 'ExpireSurvey',
        component: ExpireSurvey
    },
    {
        path: '/QRCodeList',
        name: 'QRCodeList',
        component: QRCodeList
    },
    {
        path: '/BeforeDateSurvey',
        name: 'BeforeDateSurvey',
        component: BeforeDateSurvey
    },
    {
        path: '/qrcode',
        name: 'qrcode',
        component: qrcode
    },
    {
        path: '/finalSurveyPeople',
        name: 'finalSurveyPeople',
        component: finalSurveyPeople
    },
    {
        path: '/finalSurveyOfficer',
        name: 'finalSurveyOfficer',
        component: finalSurveyOfficer
    },
    {
        path: '/SearchQR',
        name: 'SearchQR',
        component: SearchQR
    },
    {
        path: '/EditQRCode',
        name: 'EditQRCode',
        component: editQRCode
    },
    {
        path: '/CustomForm',
        name: 'CustomForm',
        component: () =>
            import ('@/views/CustomForm')
    },
    {
        path: '/Home',
        component: Homepage,
        children: [{
                path: '/ManageSurvey',
                name: 'ManageSurvey',
                component: () =>
                    import ('@/views/ManageSurvey')
            },
            {
                path: '/Form2Police',
                name: 'FormSheet2Police',
                component: FormSheet2Police
            },
            {
                path: '/CreateForm',
                name: 'CreateForm',
                component: () =>
                    import ('@/components/CreateForm.vue')
            },
            {
                path: '/report',
                name: 'report',
                component: () =>
                    import ('@/views/report.vue')
            },
            {
                path: '/report2',
                name: 'report2',
                component: () =>
                    import ('@/views/report2.vue')
            },
            {
                path: '/addKum',
                name: 'addKum',
                component: () =>
                    import ('@/views/addKum.vue')
            },
            {
                path: '/EditKum',
                name: 'EditKum',
                component: () =>
                    import ('@/views/EditKum.vue')
            },
            {
                path: '/ManageKum',
                name: 'ManageKum',
                component: () =>
                    import ('@/views/ManageKum.vue')
            },
            {
                path: '/UpdateForm',
                name: 'UpdateForm',
                component: () =>
                    import ('@/components/UpdateForm.vue')
            },
            {
                path: '/CreateQuestion',
                name: 'CreateQuestion',
                component: () =>
                    import ('@/components/CreateQuestion')
            },
            {
                path: '/UpdateQuestion',
                name: 'UpdateQuestion',
                component: () =>
                    import ('@/components/UpdateQuestion.vue')
            },
            {
                path: '/ManageQuestions',
                name: 'ManageQuestions',
                component: () =>
                    import ('@/components/ManageQuestions')
            },
            {
                path: '/ManageQRCode',
                name: 'ManageQRCode',
                component: () =>
                    import ('@/views/ManageQRCode')
            },
            {
                path: '/QRList',
                name: 'QRList',
                component: () =>
                    import ('@/views/QRCodeList')
            },
            {
                path: '/ManageForm3',
                name: 'ManageFormSheet3',
                component: () =>
                    import ('@/views/DrugForm/ManageFormSheet3')
            },
            {
                path: '/EditForm3',
                name: 'EditFormSheet3',
                component: () =>
                    import ('@/views/DrugForm/EditFormSheet3')
            },

            {
                path: '/Form3',
                name: 'FormSheet3',
                component: FormSheet3
            },

            {
                path: '/viewForm3',
                name: 'viewForm3',
                component: () =>
                    import ('@/views/DrugForm/viewForm3')
            },
            {
                path: '/Form4',
                name: 'FormSheet4',
                component: FormSheet4
            },
            {
                path: '/ManageForm4',
                name: 'ManageFormSheet4',
                component: () =>
                    import ('@/views/DrugForm/ManageFormSheet4')
            },
            {
                path: '/ManageDrugClues',
                name: 'ManageDrugClues',
                component: () =>
                    import ('@/views/DrugForm/ManageDrugClues')
            },
            {
                path: '/EditDrugClues',
                name: 'EditDrugClues',
                component: () =>
                    import ('@/views/DrugForm/EditDrugClues')
            },
            {
                path: '/ViewDrugClues',
                name: 'ViewDrugClues',
                component: () =>
                    import ('@/views/DrugForm/ViewDrugClues')
            },
            {
                path: '/ManageUser',
                name: 'ManageUser',
                component: () =>
                    import ('@/views/ManageUser')
            },
            {
                path: '/addUser',
                name: 'addUser',
                component: () =>
                    import ('@/views/addUser')
            },
            {
                path: '/editUser',
                name: 'editUser',
                component: () =>
                    import ('@/views/editUser')
            },
            {
                path: '/viewUser',
                name: 'viewUser',
                component: () =>
                    import ('@/views/viewUser')
            },
            {
                path: '/ReportXray',
                name: 'ReportXray',
                component: () =>
                    import ('@/views/ReportXray')
            },
            {
                path: '/ReportDrugClues',
                name: 'ReportDrugClues',
                component: () =>
                    import ('@/views/ReportDrugClues')
            },
            {
                path: '/SummaryForm',
                name: 'SummaryForm',
                component: () =>
                    import ('@/views/SurveyNew/SummaryForm')
            },
            {
                path: '/createResearchForm',
                name: 'createResearchForm',
                component: () =>
                    import ('@/views/SurveyNew/createResearchForm')
            },
            {
                path: '/ResearchForm',
                name: 'ResearchForm',
                component: () =>
                    import ('@/views/SurveyNew/ResearchForm')
            },
            {
                path: '/ViewResearchForm',
                name: 'ViewResearchForm',
                component: () =>
                    import ('@/views/SurveyNew/ViewResearchForm')
            },
            {
                path: '/DoSummary',
                name: 'DoSummary',
                component: () =>
                    import ('@/views/SurveyNew/DoSummary')
            },
            {
                path: '/ViewResearch',
                name: 'ViewResearch',
                component: () =>
                    import ('@/views/SurveyNew/ViewResearch')
            },
            {
                path: '/reportResearch',
                name: 'reportResearch',
                component: () =>
                    import ('@/views/SurveyNew/ReportResearch')
            },
            {
                path: '/ManageResearchForm',
                name: 'ManageResearchForm',
                component: () =>
                    import ('@/views/SurveyNew/ManageResearchForm')
            },
            {
                path: '/RawReport',
                name: 'RawReport',
                component: () =>
                    import ('@/views/RawReport')
            },
            //   { path: '/Report', name: 'Report', component: () => import('@/views/AccidentReport') },
            //   { path: '/History', name: 'History', component: () => import('@/views/IncidentHistory') },
            //   { path: '/EditUser', name: 'EditUser', component: () => import('@/views/EditUser') },
            //   { path: '/CreateUser', name: 'CreateUser', component: () => import('@/views/CreateUser') },
            //   { path: '/AddService', name: 'AddService', component: () => import('@/views/ListService') }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router