<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 24px" class="headtitle">
            ผลการปฏิบัติโครงการดำเนินงานชุมชนยั่งยืน
          </p>
          <p style="font-size: 24px" class="headtitle">
            เพื่อแก้ไขปัญหายาเสพติด แบบครบวงจร ตามยุทธศาสตร์ชาติ
          </p>
          <!-- <p style="font-size: 24px" class="headtitle">
            ตำรวจภูธรภาค {{ checklogin.station }}
          </p> -->
          <br />

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="สถานี"
                outlined
                v-model="data.station"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-menu
                ref="menufrom"
                v-model="menufrom"
                :close-on-content-click="false"
                :return-value.sync="data.datefrom"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.datefrom"
                    label="วันที่บันทึกข้อมูลล่าสุด"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="true"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="data.datefrom" no-title scrollable>
                  <v-btn text color="primary" @click="menufrom = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menufrom.save(data.datefrom)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ผู้ที่บันทึกข้อมูลล่าสุด"
                outlined
                v-model="staffName"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <h3>บันทึกจำนวนประชากร/เป้าหมาย</h3>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จำนวนประชากรตามทร.14"
                v-model="data.sumPeople14"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จำนวนครัวเรือนตาม ทร.14"
                v-model="data.sumHouse"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <h3>ผลการสำรวจครัวเรือน</h3>
          </v-row> -->
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จำนวนประชากรจริงในชุมชน"
                v-model="data.people1"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จำนวนครัวเรือนจริงในชุมชน"
                v-model="data.people2"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จำนวนครัวเรือนที่ตกลงทำ mou กับโครงการ"
                v-model="data.mou1"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จำนวนคุ้มในชุมชน"
                v-model="data.people3"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จำนวนเป้าหมายการ x-ray ในชุมชน"
                v-model="data.xray1"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="อายุ สูงกว่า 65 ปี"
                v-model="data.people6"
                type="number"
                outlined
              ></v-text-field>
            </v-col> -->
            <!-- </v-row> -->
            <!-- <v-row>
            <h3>ผลการสำรวจครัวเรือน/คุ้ม</h3>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จำนวนครัวเรือนที่ตกลงทำ MOU กับโครงการ"
                v-model="data.mou1"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <h3>เป้าหมายการ X-ray</h3>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ประชากรที่ต้อง X-ray อายุระหว่าง 12 - 65 ปี"
                v-model="data.xray1"
                type="number"
                outlined
              ></v-text-field>
            </v-col> -->
            <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ตรวจปัสสาวะแล้ว(คน)"
                v-model="data.xray2"
                type="number"
                outlined
                :disabled="true"
              ></v-text-field>
            </v-col> -->
          </v-row>
          <!-- <v-row>
            <h3>ผลการดำเนินการ X-ray</h3>
          </v-row>
          <v-row> -->
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="พบสารเสพติดในปัสสะวะ"
                v-model="data.xray01"
                type="number"
                outlined
                :disabled="true"
              ></v-text-field>
            </v-col> -->
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ไม่ได้ X-ray (แสดงตัวก่อน)"
                v-model="data.xray02"
                type="number"
                outlined
              ></v-text-field>
            </v-col> -->
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="รวมจำนวนบำบัดกับโครงการฯ"
                v-model="data.xray03"
                type="number"
                outlined
                :disabled="true"
              ></v-text-field>
            </v-col> -->
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ผู้ค้า"
                v-model="data.xray04"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <h3>ร้อยละ</h3>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ร้อยละสมัครใจบำบัด ต่อจำนวนประชากรจริง"
                v-model="data.percent1"
                type="number"
                outlined
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ร้อยละสมัครใจบำบัดต่อจำนวนประชากรX-Ray"
                v-model="data.percent2"
                type="number"
                outlined
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <h3>มอบบัตรพลเรือน สีขาว</h3>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="บุคคล"
                v-model="data.white1"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ครัวเรือน"
                v-model="data.white2"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="คุ้ม"
                v-model="data.white3"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row> -->
          <!-- <v-row><h3>ผลการบันทึก</h3></v-row>
            <v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  label="ผลการบันทึกในระบบweb app"
                  v-model="data.result1"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  label="ผลการระบบบสต."
                  v-model="data.result2"
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row> -->
          <v-row>
            <v-col><v-btn @click="cancel()">ยกเลิก</v-btn></v-col>
            <v-col
              ><v-btn color="primary" @click="submit()">บันทึก</v-btn></v-col
            >
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      checkValidate: true,
      lazy: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [(v) => v.length <= 13 || "Max 13 characters"],
        maxlength10: [(v) => v.length <= 10 || "Max 10 characters"],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checklogin: [],
      data: {
        sumPeople14: null,
        sumHouse: null,
        people1: null,
        people2: null,
        people3: null,
        people4: null,
        people5: null,
        people6: null,
        mou1: null,
        mou2: null,
        xray1: null,
        xray2: null,
        xray01: null,
        xray02: null,
        xray03: null,
        xray04: null,
        percent1: null,
        percent2: null,
        white1: null,
        white2: null,
        white3: null,
        result1: null,
        result2: null,
        station: "",
        // datefrom: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
        //   .toISOString()
        //   .substr(0, 10),
        datefrom: new Date(new Date()).toISOString().substr(0, 10),
        userId: "",
      },
      items: [],
      menufrom: false,
      staffName: "",
      // saleCase: "",
      // possessionSaleCase: "",
      // possessionCase: "",
      // drugCase: "",
    };
  },
  async created() {
    var checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
    console.log(this.checklogin);
    this.data.userId = checklogin.id;
    this.data.station = checklogin.station;
    this.searchDropdown();

    const response = await this.axios.post(
      `${process.env.VUE_APP_API}/summaryReport/findAll?station=` +
        this.data.station,
      {}
    );

    console.log(response);
    if (response.data.data.length > 0) {
      this.data = response.data.data[0];
      this.data.datefrom = this.convertDate(response.data.data[0].updatedAt);
    }

    const responseUser = await this.axios.get(
      `${process.env.VUE_APP_API}/user/getOne/` + response.data.data[0].userId
    );
    if (responseUser.data.response_status == "SUCCESS") {
      console.log(responseUser);
      this.staffName = responseUser.data.data.name;
      console.log(this.staffName);
    } else {
      this.staffName = "-";
    }
  },
  methods: {
    convertDate(date) {
      return moment(String(date)).format("MM/DD/YYYY hh:mm");
    },
    cancel() {
      this.$router.push("/report");
    },
    async searchDropdown() {
      // const response = await this.axios.get(
      //   `https://policesurveyservice.yuzudigital.com/form/getAllStation`
      // );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        // item.searchText = item.station + " " + item.village;
        item.searchText = item.station;
      });
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        // console.log("เข้าเฉย");

        console.log("data", this.data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/summaryReport/create`,
          this.data
        );
        console.log("response", response);
        if (response.data.response_status === "SUCCESS") {
          console.log("response", response);
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          this.loading = false;
          location.reload();
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "บันทึกข้อมูลไม่สำเร็จ",
          });
        }
      }
    },
    showPicture1(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase2 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase2);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
