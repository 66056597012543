<template>
  <v-container>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 24px" class="headtitle">
            ระบบบันทึกข้อมูลการ X-Ray
          </p>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="รหัสประจำตัวประชาชน/ เลขพาสปอร์ต"
                v-model="identityId"
                counter="13"
                :rules="rules.maxlength13"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="name"
                label="ชื่อ-นามสกุล"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field v-model="nickname" label="ชื่อเล่น"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="gender"
                label="เพศ"
                :items="['ชาย', 'หญิง']"
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="age"
                label="อายุ"
                :rules="rules.age"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="nationality"
                label="สัญชาติ"
                :items="['ไทย', 'ลาว', 'เมียร์มาร์', 'กัมพูชา', 'อื่นๆ']"
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
              xs="12"
              v-if="nationality == 'อื่นๆ'"
              :rules="rules.required"
            >
              <v-text-field
                v-model="otherNationality"
                label="สัญชาติอื่นๆ ระบุ"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field v-model="address" label="ที่อยู่"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="urine"
                :items="urineItems"
                label="การตรวจปัสสาวะบุคคล"
                :rules="rules.required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-checkbox
                v-model="acceptFlag"
                label="สมัครใจบำบัด"
              ></v-checkbox>
            </v-col>
            <v-row v-if="urine == 'พบสารเสพติด' || acceptFlag == true">
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="phone"
                  label="หมายเลขโทรศัพท์"
                  counter="10"
                  type="number"
                  :rules="rules.phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="caseType"
                  :items="[
                    'เสพ',
                    'จำหน่าย',
                    'ครอบครองเพื่อจำหน่าย',
                    'ครอบครอง',
                  ]"
                  label="ลักษณะการทำความผิด"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="drugTimes"
                  :items="[
                    'ผู้ใช้ (ผู้เสพรายใหม่เพิ่งเสพครั้งแรก)',
                    'ผู้เสพ (เคยเสพมาก่อน ผ่านการบำบัดไม่เกิน 5 ครั้ง)',
                    'ผู้ติด (เคยเสพมาก่อน ผ่านการบำบัดมากกว่า 5 ครั้ง',
                    'ผู้ติด+ผู้ป่วยจิตเวชจากการใช้สารเสพติด',
                  ]"
                  label="ลักษณะการเสพ"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="picture_1"
                  @click="changePic1()"
                  :placeholder="imageName1"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/*"
                  @change="showPicture1"
                  style="display: none"
              /></v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="picture_2"
                  @click="changePic2()"
                  :placeholder="imageName2"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure2"
                  accept="image/*"
                  @change="showPicture2"
                  style="display: none"
              /></v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="drugType"
                  :items="[
                    'ยาบ้า',
                    'เฮโรอีน',
                    'ไอซ์',
                    'กัญชา',
                    'กระท่อม',
                    'ยาเค',
                    'อื่นๆ',
                  ]"
                  label="สารเสพติดที่พบ"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="drugType == 'อื่นๆ'">
                <v-text-field
                  v-model="drugTypeOther"
                  label="ระบุสารเสพติดที่พบ"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="otherPerson"
                  :items="['มี', 'ไม่มี']"
                  label="บุคคลผู้เกี่ยวข้อง"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="otherPerson == 'มี'">
                <v-text-field
                  v-model="otherPersonDetail"
                  label="รายละเอียดผู้เกี่ยวข้อง"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="treatment"
                  :items="['เข้ารับการบำบัด', 'ไม่เข้ารับการบำบัด']"
                  label="เข้ารับการบำบัดหรือไม่"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine2"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 2"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine3"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 3"
                ></v-select>
              </v-col> -->
            </v-row>
          </v-row>
          <v-row>
            <v-col><v-btn @click="cancel()">ยกเลิก</v-btn></v-col>
            <v-col
              ><v-btn color="primary" @click="submit(identityId)"
                >บันทึก</v-btn
              ></v-col
            >
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      checkValidate: true,
      lazy: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [
          (v) => v.length <= 13 || "Max 13 characters",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        age: [
          (v) => v.length == 2 || "อายุต้องอยู่ระหว่าง 12-65 ปี เท่านั้น",
          (v) => v >= 12 || "อายุต้องอยู่ระหว่าง 12-65 ปี เท่านั้น",
          (v) => v <= 65 || "อายุต้องอยู่ระหว่าง 12-65 ปี เท่านั้น",
        ],
        phone: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "หมายเลขโทรศัพท์ต้องมี 9-10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      identityId: "",
      name: "",
      nickname: "",
      gender: "",
      age: "",
      maxAge: 2,
      address: "",
      nationality: "",
      otherNationality: "",
      urine: "",
      urineItems: [
        { text: "ไม่พบสารเสพติด", value: "ไม่พบสารเสพติด" },
        { text: "พบสารเสพติด", value: "พบสารเสพติด" },
      ],
      urine2: "",
      urine3: "",
      acceptFlag: false,
      phone: "",
      drugTimes: "",
      drugType: "",
      drugTypeOther: "",
      otherPerson: "",
      otherPersonDetail: "",
      picture_1: "",
      imageName1: "ภาพถ่าย(ถ้ามี)",
      picture_2: "",
      imageName2: "ภาพถ่าย(ถ้ามี)",
      imageBase1: "",
      imageBase2: "",
      caseType: "",
      treatment: "",
      sum: null,
      idCardCheck: false,
      // saleCase: "",
      // possessionSaleCase: "",
      // possessionCase: "",
      // drugCase: "",
    };
  },
  created() {},
  methods: {
    async checkIdcard(id) {
      this.sum = null;
      if (this.nationality == "ไทย") {
        for (let i = 0; i < 12; i++) {
          // const element = array[index];
          this.sum += parseFloat(id.charAt(i)) * (13 - i);
          console.log(this.sum, "sum");
        }
        // console.log(parseFloat(id.charAt(12)), "หลัง");
        // console.log((11 - (this.sum % 11)) % 10, "หน้า");
        if ((11 - (this.sum % 11)) % 10 == parseFloat(id.charAt(12))) {
          console.log(this.sum, "ผ่าน");
          this.idCardCheck = true;
        } else if ((11 - (this.sum % 11)) % 10 != parseFloat(id.charAt(12))) {
          console.log(this.sum, "ไม่ผ่าน");
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "หมายเลขบัตรประชาชนไม่ถูกต้อง",
          });
        }
      } else if (this.nationality != "ไทย") {
        this.idCardCheck = true;
      }
    },
    cancel() {
      this.$router.push("/report");
    },
    async submit(id) {
      await this.checkIdcard(id);
      if (this.$refs.createForm.validate(true) && this.idCardCheck) {
        // console.log("เข้าเฉย");
        this.checklogin = JSON.parse(
          Decode.decode(localStorage.getItem("user"))
        );
        console.log(this.checklogin);

        const data = {
          identityId: this.identityId,
          name: this.name,
          nickname: this.nickname,
          gender: this.gender,
          age: this.age,
          address: this.address,
          nationality: this.nationality,
          otherNationality: this.otherNationality,
          urine: this.urine,
          acceptFlag: this.acceptFlag,
          // urine2: this.urine2,
          // urine3: this.urine3,
          phone: this.phone,
          drugTimes: this.drugTimes,
          drugType: this.drugType,
          otherPerson: this.otherPerson,
          otherPersonDetail: this.otherPersonDetail,
          treatment: this.treatment,
          caseType: this.caseType,
          pic1: this.imageBase1 || "",
          pic2: this.imageBase2 || "",
          area: this.checklogin.area,
          province: this.checklogin.province,
          station: this.checklogin.station,
          userId: this.checklogin.id,
        };
        console.log("data", data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/drugrelatedlist/create`,
          // `http://localhost:8081/drugrelatedlist/create`,
          data
        );
        console.log("response", response);
        if (response.data.response_status === "SUCCESS") {
          console.log("response", response);
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          location.reload();
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "บันทึกข้อมูลไม่สำเร็จ",
          });
        }
      }
    },
    showPicture1(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase2 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase2);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
