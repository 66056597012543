<template>
  <v-container>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 18px" class="headtitle">
            แก้ไขการผูก QR Code กับ แบบสอบถาม
          </p>
          <!-- <pre>{{questionName}}</pre> -->
          <!-- <v-row>
            <v-col cols="12" md="12" sm="12" xs="12">
              <p>{{ qrCodeType }}</p>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-select
                v-model="qrCodeType"
                :items="qrCodeTypeItems"
                label="เลือกประเภท QR Code"
              ></v-select>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="qrCodeType">
                เลือกหัวข้อ QR Code
                <v-radio
                  v-for="n in qrCodeTypeItems"
                  :key="n"
                  :label="`${n}`"
                  :value="n"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="qrCodeQuestion">
                เลือกแบบสอบถามเพื่อผูกกับ QR Code
                <v-radio
                  v-for="n in qrCodeQuestionItems"
                  :key="n"
                  :label="`${n}`"
                  :value="n"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-btn @click="cancel()">ย้อนกลับ</v-btn>
          <v-btn color="#833133" dark @click="submit()" class="mx-4"
            >บันทึก</v-btn
          >

          <!-- <v-spacer></v-spacer> -->
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      lazy: "",
      checkValidate: "",
      qrCodeType: "",
      qrCodeTypeItems: [
        "แบบประเมินประสิทธิภาพโครงการดำเนินงานชุมชนยั่งยืนเพื่อแก้ปัญหายาเสพติดแบบครบวงจรตามยุทธศาสตร์ชาติ (สำหรับประชาชน)",
        "แบบประเมินประสิทธิภาพโครงการดำเนินงานชุมชนยั่งยืนเพื่อแก้ปัญหายาเสพติดแบบครบวงจรตามยุทธศาสตร์ชาติ (สำหรับชุดปฏิบัติการ)",
      ],
      qrCodeQuestion: "",
      qrCodeQuestionItems: [
        "แบบประเมินการฝึกอบรมชุดปฏิบัติการระดับภาค	สำหรับผู้ฝึกอบรม",
        "แบบประเมินการฝึกอบรมชุดปฏิบัติการระดับภาค	สำหรับ วิทยากร/ผู้บริหารจัดการโครงการ",
        "แบบประเมินประสิทธิภาพโครงการดำเนินงานชุมชนยั่งยืนเพื่อแก้ปัญหายาเสพติดแบบครบวงจรตามยุทธศาสตร์ชาติ	สำหรับชุดปฏิบัติการ",
        "แบบประเมินประสิทธิภาพโครงการดำเนินงานชุมชนยั่งยืนเพื่อแก้ปัญหายาเสพติดแบบครบวงจรตามยุทธศาสตร์ชาติ	สำหรับประชาชนในพื้นที่ของชุดปฏิบัติการ",
      ],
    };
  },
  async created() {
    // this.user.station = getuser.station;
  },
  methods: {
    cancel() {
      this.$router.push("ManageQRcode");
    },
    async submit() {
      await this.$swal.fire({
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        icon: "success",
        text: "บันทึกข้อมูลสำเร็จ",
      });
      this.$router.push("ManageQRcode");
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
.v-select.fit {
  width: max-content;
}
</style>