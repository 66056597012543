<template>
  <v-card>
    <v-container>
      <h2 class="headtitle">ข้อมูลผู้ใช้งาน</h2>
      <v-row>
        <v-col cols="4" class="mr-4">
          <v-text-field
            class="mb-4"
            v-model="search"
            dense
            hide-details
            outlined
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-4 mt-4"
          color="#833133"
          style="color: white"
          @click="createForm()"
          ><v-icon left dark> mdi-plus-circle </v-icon>เพิ่ม
        </v-btn>
      </v-row>
      <v-data-table
        :headers="headersdrugclues"
        :items="listUser"
        :search="search"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:[`item.name`]="{ item }">
          <p>{{ item.name }}</p>
        </template>
        <template v-slot:[`item.userType`]="{ item }">
          <p>
            {{
              item.userType == "99"
                ? "ADMIN"
                : item.userType == "1"
                ? "ทั่วประเทศ"
                : item.userType == "2"
                ? "ระดับภาค"
                : item.userType == "3"
                ? "ระดับจังหวัด"
                : item.userType == "4"
                ? "ระดับสถานี"
                : ""
            }}
          </p>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <v-icon @click="viewForm(item)">mdi-eye</v-icon>
            <v-icon @click="UpdateForm(item)" class="mx-2">mdi-pencil</v-icon>
            <v-icon @click="DeleteForm(item)">mdi-delete</v-icon>

            <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
          </v-row>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      search: "",
      listUser: [],
      count: 0,
      headersdrugclues: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        { text: "ตำแหน่ง", value: "rank", align: "center" },
        { text: "เบอร์โทรศัพท์", value: "phone", align: "center" },
        { text: "ประเภทผู้ใช้งาน", value: "userType", align: "center" },
        { text: "ภาค", value: "area", align: "center" },
        // { text: "การช่วยเหลือ", value: "supportive", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      area: "",
      province: "",
      station: "",
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    createForm() {
      this.$router.push("addUser");
    },
    async getUsers() {
      // const data = {
      //   area: this.area,
      //   province: this.province,
      //   station: this.station,
      // };
      // console.log(data);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/user/getAll`
      );
      console.log("response1", response);
      console.log("response", response.data.data);
      this.listUser = response.data.data;
      for (let i in this.listUser) {
        this.listUser[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    async DeleteForm(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/user/delete/` + val.id
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          await location.reload();
        }
      });
    },
    UpdateForm(val) {
      localStorage.setItem("Userdata", Encode.encode(val));
      this.$router.push("EditUser");
    },
    viewForm(val) {
      localStorage.setItem("Userdata", Encode.encode(val));
      this.$router.push("viewUser");
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>