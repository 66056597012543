import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import UUID from "vue-uuid";
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueStarRating from 'vue-star-rating'
import SequentialEntrance from 'vue-sequential-entrance'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'vue-sequential-entrance/vue-sequential-entrance.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueQrcode from 'vue-qrcode'
import KProgress from 'k-progress';
import JsonExcel from "vue-json-excel";
import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";
import VueHtmlToPaper from 'vue-html-to-paper';
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)
Vue.use(VueHtmlToPaper);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueAxios, axios)
Vue.use(Antd)
Vue.use(SequentialEntrance)
Vue.use(VueSweetalert2);
Vue.use(UUID);
Vue.config.productionTip = false
Vue.component('apexchart', VueApexCharts)
Vue.use(VueApexCharts)
Vue.component('star-rating', VueStarRating.default);
Vue.use(VueQrcode)
Vue.use(VueAnalytics, {
  id: 'G-D4F3WF1QC7',
  router
})
Vue.use(VueGtag, {
  config: {
    id: "G-Y2SFPH1PHF",
    params: {
      send_page_view: false
    }
  }
});

Vue.component('k-progress', KProgress);

//and then use it in main.js
new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
  // render: h => h(App)
}).$mount('#app')
