<template>
  <v-container>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 18px" class="headtitle">เพิ่มข้อมูลผู้ใช้งาน</p>
          <!-- <pre>{{questionName}}</pre> -->
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="user.name"
                label="ชื่อ - นามสกุล"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="user.rank"
                label="ตำแหน่ง"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="user.phone"
                label="เบอร์โทรศัพท์"
                :rules="rules.required"
                counter="10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="user.userType"
                :items="itemsUserType"
                label="ประเภทผู้ใช้งาน"
              ></v-select>
            </v-col>
            <v-col v-if="user.userType == '2'" cols="12" md="6" sm="6" xs="12">
              <v-autocomplete
                label="เลือกภาค"
                v-model="user.area"
                :items="items2"
                item-text="group"
                item-value="group"
                :rules="rules.required"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="user.userType == '3'" cols="12" md="6" sm="6" xs="12">
              <v-autocomplete
                label="เลือกภาค"
                v-model="user.area"
                :items="items2"
                item-text="group"
                item-value="group"
                :rules="rules.required"
                @change="onChangeArea()"
              ></v-autocomplete>
              <v-autocomplete
                label="เลือกจังหวัด"
                v-model="user.province"
                :items="items3"
                item-text="province"
                item-value="province"
                :rules="rules.required"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="user.userType == '4'" cols="12" md="6" sm="6" xs="12">
              <v-autocomplete
                label="เลือกภาค"
                v-model="user.area"
                :items="items2"
                item-text="group"
                item-value="group"
                :rules="rules.required"
                @change="onChangeArea()"
              ></v-autocomplete>
              <v-autocomplete
                label="เลือกจังหวัด"
                v-model="user.province"
                :items="items3"
                item-text="province"
                item-value="province"
                :rules="rules.required"
                @change="onChangeProvince()"
              ></v-autocomplete>
              <v-autocomplete
                label="เลือกสถานี"
                v-model="user.station"
                :items="items4"
                item-text="station"
                item-value="station"
                :rules="rules.required"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-btn class="mt-4" @click="cancel()">ย้อนกลับ</v-btn>
          <v-btn color="#833133" dark @click="submit()" class="mx-4 mt-4"
            >บันทึก</v-btn
          >
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      checkValidate: "", //ห้ามลบ
      lazy: "", //ห้ามลบ
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน"],
        password: [(v) => !!v || "Password is required"],
        image: [(v) => !!v || "Image is required"],
        name: [(v) => !!v || "Name is required"],
        sername: [(v) => !!v || "Sername is required"],
        duty: [(v) => !!v || "Duty is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        selectRules: [(val) => !!val || "กรุณากรอกข้อมูล"],
      },

      village: "",
      station: "",
      district: "",
      subDistrict: "",
      province: "",
      dropdown: "",
      group: "",
      area: [],
      items: [],
      items2: [],
      items3: [],
      items4: [],
      selected: false,
      itemsUserType: [
        { text: "ADMIN", value: "99" },
        { text: "ทั่วประเทศ", value: "1" },
        { text: "ระดับภาค", value: "2" },
        { text: "ระดับจังหวัด", value: "3" },
        { text: "ระดับสถานี", value: "4" },
      ],
      user: {
        name: "",
        rank: "",
        phone: "",
        station: "",
        userType: "",
        area: "",
        province: "",
      },
    };
  },
  created() {
    this.searchDropdown();
  },
  methods: {
    async onChangeArea() {
      this.items3 = [];
      this.items4 = [];
      this.user.province = "";
      this.user.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?group=` +
          this.user.area
      );
      this.items3 = response.data.data;
    },
    async onChangeProvince() {
      this.items4 = [];
      this.user.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?province=` +
          this.user.province
      );
      this.items4 = response.data.data;
    },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);

      this.items2 = Array.from(
        new Set(this.items.map((a) => a.group.trim()))
      ).map((group) => {
        return this.items.find((a) => a.group.trim() === group.trim());
      });
      this.items3 = Array.from(
        new Set(this.items.map((a) => a.province.trim()))
      ).map((province) => {
        return this.items.find((a) => a.province.trim() === province.trim());
      });

      console.log(this.items2);

      this.items4 = response.data.data;
    },
    cancel() {
      this.$router.push("ManageUser");
    },
    async submit() {
      // this.user.station = this.dropdown.station;
      // this.user.province = this.dropdown.province;
      // this.user.group = this.dropdown.group;

      if (this.$refs.createForm.validate(true)) {
        console.log("this.user", this.user);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/user/create`,
          this.user
        );
        console.log("response", response);
        if (response.data.response_status !== "ERROR") {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          this.$router.push("ManageUser");
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "เบอร์นี้ถูกใช้แล้ว",
          });
        }
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอกข้อมูล",
        });
      }
    },
    resetValidation() {
      this.$refs.createForm.resetValidation();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
