<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <div v-if="success == true && isCanDo == true">
      <v-row no-gutters justify="center" align="center">
        <v-card height="350px" width="620px" class="mt-2">
          <v-row no-gutters justify="center" align="center" class="mt-10">
            <v-img
              max-height="100px"
              max-width="100px"
              src="../assets/logo_survey.png"
            ></v-img>
            <v-col cols="12"
              ><h2 class="text-center mt-10 headtitle">
                ส่งแบบสอบถามสำเร็จ
              </h2></v-col
            >
            <v-col cols="12" class="text-center mt-4">
              ขอบคุณที่ร่วมตอบแบบสอบถามและประเมินความพึงพอใจในครั้งนี้
            </v-col>
            <v-col cols="11" class="text-center">
              ทางเราจะปรับปรุงและพัฒนาโครงการให้ดียิ่งขึ้น
            </v-col>
          </v-row>
          <!-- <v-row no-gutters justify="center" align="center" class="mt-4"
            ><v-btn @click="again()" desnse color="primary">ทำแบบสอบถามอีกครั้ง</v-btn></v-row
          > -->
        </v-card>
      </v-row>
    </div>
    <div v-if="isCanDo && !success">
      <v-row no-gutters justify="center" align="center">
        <v-card class="pa-6 mt-2" width="600px">
          <v-row no-gutters justify="center" align="center">
            <v-img
              max-height="120px"
              max-width="120px"
              src="../assets/logo_survey.png"
            ></v-img>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <h3 class="mt-10 headtitle text-center">{{ questions.name }}</h3>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <h4 class="headtitle1">({{ questions.description }})</h4>
          </v-row>
        </v-card>

        <v-card
          class="pa-4 mt-2"
          width="600px"
          v-for="(n, index) in questions.question"
          :key="index"
          dense
        >
          <v-col cols="12" class="headtitle">{{ n.topic }}</v-col>
          <v-row>
            <v-col cols="12">
              <star-rating
                v-if="n.type == `C`"
                v-model="n.rating"
                v-bind:star-size="45"
              ></star-rating>
              <v-textarea
                class="mt-0 pt-0"
                filled
                v-else
                v-model="comment[index]"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
          <div v-if="n.isError">
            <v-chip color="red" style="color: white">กรุณากรอกข้อมูล</v-chip>
          </div>
        </v-card>

        <v-card class="pa-6 mt-2" width="600px">
          <v-btn color="#833133" style="color: white" @click="submit()"
            >ส่งแบบสอบถาม</v-btn
          >
        </v-card>
      </v-row>
    </div>

    <div v-if="!isCanDo">
      <v-row no-gutters justify="center" align="center">
        <v-card height="350px" width="620px" class="mt-2">
          <v-row no-gutters justify="center" align="center" class="mt-10">
            <v-img
              max-height="100px"
              max-width="100px"
              src="../assets/logo_survey.png"
            ></v-img>
            <v-col cols="12"
              ><h2 class="text-center mt-10 headtitle">
                ขออภัย ท่านทำแบบสอบถามไปแล้ว
              </h2></v-col
            >
            <v-col cols="12" class="text-center mt-4">
              ขอบคุณที่ร่วมตอบแบบสอบถามและประเมินความพึงพอใจในครั้งนี้
            </v-col>
            <v-col cols="11" class="text-center">
              ทางเราจะปรับปรุงและพัฒนาโครงการให้ดียิ่งขึ้น
            </v-col>
          </v-row>
          <!-- <v-row no-gutters justify="center" align="center" class="mt-4"
            ><v-btn @click="again()" desnse color="primary">ทำแบบสอบถามอีกครั้ง</v-btn></v-row
          > -->
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import StarRating from "vue-star-rating";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    StarRating,
    Loading,
  },
  data() {
    return {
      checklog: "",
      rating: [],
      comment: [],
      questions: [],
      answer: [],
      formId: "",
      location: "",
      station: "",
      village: "",
      district: "",
      subDistrict: "",
      province: "",
      isCanDo: true,
      doQuestion: false,
      loading: false,
      success: true,
    };
  },
  methods: {
    async submit() {
      var navigator_info = window.navigator;
      var screen_info = window.screen;
      var uid = navigator_info.mimeTypes.length;
      uid += navigator_info.userAgent.replace(/\D+/g, "");
      uid += navigator_info.plugins.length;
      uid += screen_info.height || "";
      uid += screen_info.width || "";
      uid += screen_info.pixelDepth || "";
      console.log("uid", uid);
      const postParam = {
        deviceId: uid,
        formId: this.formId,
        answer: [],
      };
      let index = 0;
      // this.questions
      let isAllCorrect = true;
      this.questions.question.forEach((ansQuestion) => {
        if (!ansQuestion.rating && ansQuestion.type == "C") {
          this.questions.question[index].isError = true;
          console.log("error");
          this.$swal.fire({
            icon: "warning",
            title: `ท่านตอบแบบสอบถามไม่ครบ`,
            showConfirmButton: false,
            timer: 1500,
          });
          isAllCorrect = false;
        } else {
          this.questions.question[index].isError = false;
        }
        postParam.answer.push({
          questionId: this.questions.question[index].id,
          answer: ansQuestion.rating,
          location: this.location,
          station: this.station,
          village: this.village,
          district: this.district,
          subDistrict: this.subDistrict,
          province: this.province,
        });
        index++;
      });

      // this.questions = this.questions;
      console.log(this.questions);
      if (!isAllCorrect) {
        this.$forceUpdate();
        return;
      }

      console.log("posrParam", postParam);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/form/createAnswer`,
        postParam
      );
      console.log("response", response);
      await this.$swal.fire({
        icon: "success",
        title: `ส่งแบบสอบถามสำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
      });
      this.$forceUpdate();
      this.success = true;
    },
    async getSurvey() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getForm/` +
          this.formId
      );
      this.questions = response.data.data;
      console.log("question", this.questions);
      // this.formId = this.questions.id;
    },
  },
  async created() {
    this.success = false;
    this.loading = true;
    const url = this.$router.currentRoute.query;
    console.log("url", url);
    (this.location = url.location),
      (this.station = url.station),
      (this.village = url.village),
      (this.district = url.district),
      (this.subDistrict = url.subDistrict),
      (this.province = url.province),
      (this.formId = url.formId);
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    uid += screen_info.pixelDepth || "";
    console.log("uid", uid);
    const datachecklog = {
      deviceId: uid,
      formId: this.formId,
    };

    console.log("datachecklog", datachecklog);
    const response = await this.axios.post(
      `${process.env.VUE_APP_API}/form/checkLog`,
      datachecklog
    );
    console.log("check log response", response);
    if (response.data.message === "TRUE") {
      this.isCanDo = true;
      await this.getSurvey();
    } else if (response.data.data) {
      if (response.data.data.status == "ยังไม่ทำ") this.isCanDo = true;
      await this.getSurvey();
    } else {
      this.isCanDo = false;
    }
    this.loading = false;
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
.headtitle1 {
  font-weight: 800;
  color: #833133;
}
</style>
