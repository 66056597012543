<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <div v-if="success == true && isCanDo == true">
      <v-row no-gutters justify="center" align="center">
        <v-card height="350px" width="620px" class="mt-2">
          <v-row no-gutters justify="center" align="center" class="mt-10">
            <v-img
              max-height="100px"
              max-width="100px"
              src="../assets/logo_survey.png"
            ></v-img>
            <v-col cols="12"
              ><h2 class="text-center mt-10 headtitle">
                ส่งแบบสอบถามสำเร็จ
              </h2></v-col
            >
            <v-col cols="12" class="text-center mt-4">
              ขอบคุณที่ร่วมตอบแบบสอบถามและประเมินความพึงพอใจในครั้งนี้
            </v-col>
            <v-col cols="11" class="text-center">
              ทางเราจะปรับปรุงและพัฒนาโครงการให้ดียิ่งขึ้น
            </v-col>
          </v-row>
          <!-- <v-row no-gutters justify="center" align="center" class="mt-4"
            ><v-btn @click="again()" desnse color="primary">ทำแบบสอบถามอีกครั้ง</v-btn></v-row
          > -->
        </v-card>
      </v-row>
    </div>
    <div v-if="isCanDo && !success">
      <v-row no-gutters justify="center" align="center">
        <v-card class="pa-6 mt-2" width="90%">
          <v-row no-gutters justify="center" align="center">
            <v-img
              max-height="120px"
              max-width="120px"
              src="../assets/logo_survey.png"
            ></v-img>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <h3 class="mt-10 headtitle text-center">{{ questions.name }}</h3>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <h4 class="headtitle2">({{ questions.description }})</h4>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <h4 class="headtitle1">{{ qrData.station }}</h4>
          </v-row>
        </v-card>

        <!-- <v-row no-gutters justify="center" align="center"> -->
        <v-row
          v-for="(n, index) in questions.question"
          :key="index"
          md="12"
          no-gutters
          justify="center"
          align="center"
        >
          <v-card
            v-if="n[0].header"
            class="pa-6 mt-2"
            width="90%"
            style="background-color: #c0c0c0"
          >
            <h3 class="headtitle1">{{ n[0].header }}</h3>
          </v-card>
          <v-card
            class="pa-4 mt-2"
            width="90%"
            v-for="(que, indexQ) in questions.question[index]"
            :key="indexQ"
            dense
          >
            <!-- <v-col cols="12" md="6" sm="6" xs="12"> -->
            <v-col cols="12" md="12" xs="12" class="headtitle">{{
              que.topic
            }}</v-col>
            <v-row>
              <v-col cols="12">
                <star-rating
                  v-if="que.type == `C`"
                  v-model="que.rating"
                  v-bind:star-size="45"
                ></star-rating>
                <v-textarea
                  class="mt-0 pt-0"
                  filled
                  v-if="que.type == `T`"
                  v-model="que.comment"
                  rows="3"
                ></v-textarea>
                <v-radio-group v-model="que.comment" v-if="que.type == `RADIO`">
                  <v-radio
                    :label="choice.choiceName"
                    :value="choice.choiceName"
                    v-for="(choice, indexC) in que.choices"
                    :key="indexC"
                  >
                  </v-radio>
                </v-radio-group>
                <!-- <v-form v-if="que.type == `DROPDOWN`">
                  <v-select
                    v-model="que.comment"
                    :items="que.choices"
                  ></v-select>
                </v-form> -->
                <v-form v-if="que.type == `CHECKBOX`">
                  <v-checkbox
                    multiple
                    v-for="(choice, indexC) in que.choices"
                    :key="indexC"
                    v-model="que.comment"
                    :label="choice.choiceName"
                    :value="choice.choiceName"
                  ></v-checkbox>
                </v-form>
              </v-col>
            </v-row>
            <div v-if="que.isError">
              <v-chip color="red" style="color: white">กรุณากรอกข้อมูล</v-chip>
            </div>
          </v-card>
        </v-row>
        <!-- </v-row> -->

        <v-card class="pa-6 mt-2 text-center" width="90%">
          <v-btn color="#833133" style="color: white" @click="submit()"
            >ส่งแบบสอบถาม</v-btn
          >
        </v-card>
      </v-row>
       <div>
      <v-row no-gutters justify="center" align="center">
        <br />
        <v-col cols="12" class="text-center mt-4">
          <p style="color: #fff; font-size: 0.8rem;">
            วันที่ทำแบบสอบถาม: {{ convertDate() }}
          </p>
          <p style="color: #fff; font-size: 0.8rem;">UID: {{ uid }}</p>
        </v-col>
      </v-row>
    </div>
    </div>

    <div v-if="!isCanDo">
      <v-row no-gutters justify="center" align="center">
        <v-card height="350px" width="620px" class="mt-2">
          <v-row no-gutters justify="center" align="center" class="mt-10">
            <v-img
              max-height="100px"
              max-width="100px"
              src="../assets/logo_survey.png"
            ></v-img>
            <v-col cols="12"
              ><h2 class="text-center mt-10 headtitle">
                ขออภัย ท่านทำแบบสอบถามไปแล้ว
              </h2></v-col
            >
            <v-col cols="12" class="text-center mt-4">
              ขอบคุณที่ร่วมตอบแบบสอบถามและประเมินความพึงพอใจในครั้งนี้
            </v-col>
            <v-col cols="11" class="text-center">
              ทางเราจะปรับปรุงและพัฒนาโครงการให้ดียิ่งขึ้น
            </v-col>
          </v-row>
          <!-- <v-row no-gutters justify="center" align="center" class="mt-4"
            ><v-btn @click="again()" desnse color="primary">ทำแบบสอบถามอีกครั้ง</v-btn></v-row
          > -->
        </v-card>
      </v-row>
      <div>
      <v-row no-gutters justify="center" align="center">
        <br />
        <v-col cols="12" class="text-center mt-4">
          <p style="color: #fff; font-size: 0.8rem;">
            วันที่ทำแบบสอบถาม: {{ convertDate() }}
          </p>
          <p style="color: #fff; font-size: 0.8rem;">UID: {{ uid }}</p>
        </v-col>
      </v-row>
    </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import StarRating from "vue-star-rating";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Encode, Decode } from "@/services";
export default {
  components: {
    StarRating,
    Loading,
  },
  data() {
    return {
      checklog: "",
      rating: [],
      comment: [],
      questions: [],
      answer: [],
      formId: "",
      location: "",
      station: "",
      village: "",
      district: "",
      subDistrict: "",
      province: "",
      isCanDo: true,
      doQuestion: false,
      loading: false,
      success: true,
      qrCodeId: 0,
      qrForm: [],
      qrData: null,
      uid: "",
    };
  },
  methods: {
    convertDate() {
      return moment().format("MM/DD/YYYY hh:mm");
    },
    doMath: function(index) {
      return index + 1;
    },
    async submit() {
      // var navigator_info = window.navigator;
      // var screen_info = window.screen;
      // var uid = navigator_info.mimeTypes.length;
      // uid += navigator_info.userAgent.replace(/\D+/g, "");
      // uid += navigator_info.plugins.length;
      // uid += screen_info.height || "";
      // uid += screen_info.width || "";
      // uid += screen_info.pixelDepth || "";
      // console.log("uid", uid);
      const postParam = {
        deviceId: this.uid,
        formId: this.formId,
        station: this.qrData.station,
        answer: [],
      };

      // this.questions
      let isAllCorrect = true;
      for (const [key, value] of Object.entries(this.questions.question)) {
        console.log(`${key}: ${value}`);
        let index = 0;
        this.questions.question[key].forEach((ansQuestion) => {
          if (!ansQuestion.rating && ansQuestion.type == "C") {
            this.questions.question[key][index].isError = true;
            console.log("error");
            this.$swal.fire({
              icon: "warning",
              title: `ท่านตอบแบบสอบถามไม่ครบ`,
              showConfirmButton: false,
              timer: 1500,
            });
            isAllCorrect = false;
          } else {
            this.questions.question[key][index].isError = false;
          }

          if (ansQuestion.rating && ansQuestion.type == "C") {
            postParam.answer.push({
              questionId: this.questions.question[key][index].id,
              answer: ansQuestion.rating,
              location: this.qrData.location,
              station: this.qrData.station,
              village: this.qrData.village,
              district: this.qrData.district,
              subDistrict: this.qrData.subDistrict,
              province: this.qrData.province,
            });
          }
          if (ansQuestion.type == "T" || ansQuestion.type == "RADIO") {
            postParam.answer.push({
              questionId: this.questions.question[key][index].id,
              answer: ansQuestion.comment,
              location: this.qrData.location,
              station: this.qrData.station,
              village: this.qrData.village,
              district: this.qrData.district,
              subDistrict: this.qrData.subDistrict,
              province: this.qrData.province,
            });
          }
          if (ansQuestion.type == "CHECKBOX") {
            if (ansQuestion.comment) {
              ansQuestion.comment.forEach((selected) => {
                postParam.answer.push({
                  questionId: this.questions.question[key][index].id,
                  answer: selected,
                  location: this.qrData.location,
                  station: this.qrData.station,
                  village: this.qrData.village,
                  district: this.qrData.district,
                  subDistrict: this.qrData.subDistrict,
                  province: this.qrData.province,
                });
              });
            }
          }
          index++;
        });
      }
      // this.questions.question.forEach((ansQuestion) => {
      //   if (!ansQuestion.rating && ansQuestion.type == "C") {
      //     this.questions.question[index].isError = true;
      //     console.log("error");
      //     isAllCorrect = false;
      //   } else {
      //     this.questions.question[index].isError = false;
      //   }
      //   postParam.answer.push({
      //     questionId: this.questions.question[index].id,
      //     answer: ansQuestion.rating,
      //     location: this.location,
      //     station: this.station,
      //     village: this.village,
      //     district: this.district,
      //     subDistrict: this.subDistrict,
      //     province: this.province,
      //   });
      //   index++;
      // });

      // this.questions = this.questions;
      console.log(this.questions);
      if (!isAllCorrect) {
        this.$forceUpdate();
        return;
      }

      console.log("posrParam", postParam);
      const response = await this.axios.post(
        // `http://localhost:8081/form/createAnswer`,
        `${process.env.VUE_APP_API}/form/createAnswer`,
        postParam
      );
      console.log("response", response);
      await this.$swal.fire({
        icon: "success",
        title: `ส่งแบบสอบถามสำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
      });
      // location.reload();
      this.$forceUpdate();
      this.success = true;
      console.log("isCanDo", this.isCanDo);
      console.log("success", this.success);
    },
    async getSurvey() {
      // const response = await this.axios.get(
      //   `${process.env.VUE_APP_API}/form/getForm2/` +
      //     this.formId
      // );
      this.questions = this.qrForm;
      console.log("question", this.questions);
      // this.formId = this.questions.id;
    },
  },
  async created() {
    this.success = false;
    this.loading = true;
    const url = this.$router.currentRoute.query;
    console.log("url", url);
    this.village = url.village;
    this.question1 = url.station;
    this.qrCodeId = url.qrCodeId;
    // var navigator_info = window.navigator;
    // var screen_info = window.screen;
    // var uid = navigator_info.mimeTypes.length;
    // uid += navigator_info.userAgent.replace(/\D+/g, "");
    // uid += navigator_info.plugins.length;
    // uid += screen_info.height || "";
    // uid += screen_info.width || "";
    // uid += screen_info.pixelDepth || "";
    // console.log("uid", uid);
    // this.uid = uid;
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var result = [];
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }

    var genString = result.join("");

    if (
      Object.prototype.hasOwnProperty.call(localStorage, "policeSurvey_survey")
    ) {
      var checkPeoplepollSurvey = JSON.parse(
        Decode.decode(localStorage.getItem("policeSurvey_survey"))
      ).uid;
      if (checkPeoplepollSurvey !== "") {
        console.log(checkPeoplepollSurvey);
        this.uid = checkPeoplepollSurvey;
      } else {
        var uid = navigator_info.mimeTypes.length;
        uid += navigator_info.userAgent.replace(/\D+/g, "");
        uid += navigator_info.plugins.length;
        uid += screen_info.height || "";
        uid += screen_info.width || "";
        uid += screen_info.pixelDepth || "";
        uid += moment().valueOf();
        uid += genString;
        console.log("uid", uid);
        localStorage.setItem("policeSurvey_survey", Encode.encode({ uid }));
        this.uid = uid;
      }
    } else {
      var uid = navigator_info.mimeTypes.length;
      uid += navigator_info.userAgent.replace(/\D+/g, "");
      uid += navigator_info.plugins.length;
      uid += screen_info.height || "";
      uid += screen_info.width || "";
      uid += screen_info.pixelDepth || "";
      uid += moment().valueOf();
      uid += genString;
      console.log("uid", uid);
      localStorage.setItem("policeSurvey_survey", Encode.encode({ uid }));
      this.uid = uid;
    }

    const qrResponse = await this.axios.get(
      `${process.env.VUE_APP_API}/form/getQrCode/
        ${this.qrCodeId}`
      // "http://localhost:8081/form/getQrCode/" +
      //   this.qrCodeId
    );
    console.log("check log response", qrResponse);
    // if(!qrResponse.data.qrData.formId){
    //   this.$router.push("BeforeDateSurvey");
    //   return;
    // }
    if (qrResponse.data.response_status === "SUCCESS") {
      this.qrForm = qrResponse.data.data;
      this.qrData = qrResponse.data.qrData;
      this.formId = this.qrData.formId;

      console.log(this.qrdata);

      if (this.formId !== 20) {
        const datachecklog = {
          deviceId: this.uid,
          formId: this.formId,
          station: this.qrData.station,
        };
        console.log("datachecklog", datachecklog);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/form/checkLog`,
          datachecklog
        );
        console.log("check log response", response);
        if (response.data.message === "TRUE") {
          this.isCanDo = true;
          await this.getSurvey();
        } else if (response.data.data) {
          if (response.data.data.status == "ยังไม่ทำ") this.isCanDo = true;
          await this.getSurvey();
        } else {
          this.isCanDo = false;
        }
        this.loading = false;
      } else {
        window.open(
          `${process.env.VUE_APP_FRONTEND}/customform?station=` +
            this.qrData.station +
            "&village=" +
            this.qrData.village,
          "_self"
        );
      }
    } else {
      this.$router.push("BeforeDateSurvey");
    }
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
.headtitle1 {
  font-weight: 800;
  color: #833133;
}
.headtitle2 {
  font-weight: 800;
  color: #000000;
}
</style>
